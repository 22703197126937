<template>
  <b-overlay :show="loading">
    <b-row class="match-height">
      <b-col>
        <b-card-actions action-collapse :title="'Daftar Kategori Akun'">
          <b-row>
            <b-col md="4" sm="4">
              <b-button
                v-b-modal.modal-tambah
                variant="primary"
                @click="add()"
                v-if="allowCreate()"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Kategori Akun
              </b-button>
            </b-col>
          </b-row>
          <b-modal
            v-model="showModal"
            id="modal-"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
            title="Form "
          >
            <validation-observer ref="formakun_kategori">
              <b-form>
                <b-row>
                  <!-- kategori -->
                  <b-col cols="12" class="mb-2">
                    <b-form-group label="Kategori" label-for="v-kategori">
                      <validation-provider
                        #default="{ errors }"
                        name="kategori"
                      >
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          v-model="form.kategori"
                          id="v-kategori"
                          placeholder="Ex: Beban"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
  
                  <!-- laba rugi -->
                  <b-col sm="12" md="6">
                    <b-form-group label="Tampilkan di Laba Rugi?" label-for="v-laba_rugi">
                      <validation-provider
                        #default="{ errors }"
                        :rules="`required`"
                        name="laba_rugi"
                      >
                        <div>
                          <b-form-radio-group
                            v-model="form.laba_rugi"
                            :options="[{value: 1, text: 'Ya'}, {value: 0, text: 'Tidak'}]"
                            class="inline-spacing"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- laba rugi -->
                  <b-col sm="12" md="6">
                    <b-form-group label="Tampilkan di Neraca?" label-for="v-neraca">
                      <validation-provider
                        #default="{ errors }"
                        :rules="`required`"
                        name="neraca"
                      >
                        <div>
                          <b-form-radio-group
                            v-model="form.neraca"
                            :options="[{value: 1, text: 'Ya'}, {value: 0, text: 'Tidak'}]"
                            class="inline-spacing"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
  
                </b-row>
              </b-form>
            </validation-observer>
  
            <template #modal-footer>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                @click.prevent="submit"
                variant="primary"
                class="mr-1"
              >
                Simpan
              </b-button>
            </template>
          </b-modal>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                :label="$t('Sort')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                :label="$t('Filter')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
  
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(order)="{item}">
                  <a class="d-block" href="#" @click.prevent="changeOrder(item.id, 'up')" v-if="['middle', 'last'].includes(item.position)">
                    <feather-icon icon="ArrowUpIcon" size="24"></feather-icon>
                  </a>
                  <a class="d-block" href="#" @click.prevent="changeOrder(item.id, 'down')" v-if="['middle', 'first'].includes(item.position)">
                    <feather-icon icon="ArrowDownIcon" size="24"></feather-icon>
                  </a>
                </template>
                <template #cell(no)="data">
                  {{ data.index + 1 }}
                </template>
  
                <template #cell(neraca)="{item}">
                  <b-badge :variant="item.neraca == 1 ? 'light-success' : 'light-danger'">
                      {{ item.neraca == 1 ? 'Ya' : 'Tidak' }}
                  </b-badge>
                </template>
                <template #cell(laba_rugi)="{item}">
                  <b-badge :variant="item.laba_rugi == 1 ? 'light-success' : 'light-danger'">
                      {{ item.laba_rugi == 1 ? 'Ya' : 'Tidak' }}
                  </b-badge>
                </template>
  
                <template #cell(actions)="row">
                  <b-button
                    v-b-tooltip.hover.right="'Ubah'"
                    v-if="allowUpdate()"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>
            
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  BOverlay,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormRadio,
  BButton,
  BFormTextarea,
  BCardText,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BForm,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormRadio,
    BCardText,
    BFormRadioGroup,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        kategori: null,
        laba_rugi: null,
        neraca: null
      },
      jenis: [
        { value: 1, text: "Masuk" },
        { value: 2, text: "Keluar" },
      ],
      id_karyawan: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "order", label: "Order" },
        { key: "no", label: "no" },
        { key: "kategori", label: "Kategori", sortable: true },
        { key: "laba_rugi", label: "Tampilkan di Laba Rugi"}, 
        { key: "neraca", label: "Tampilkan di Neraca"}, 
        { key: "actions", label: "Aksi" }
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
  },
  mounted() {
    this.getData();
    // this.getkaryawan();
  },
  methods: {
    async changeOrder(id_kategori, aksi) {
      const payload = {
        id_kategori,
        aksi
      }

      try {
        this.loading = true
        await this.$store.dispatch('akun_kategori/changeOrder', [payload])
        await this.getData()
        this.loading = false
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      // const userData = JSON.parse(localStorage.getItem("userData"));
      // if (userData.id) {
      //   this.form.id_karyawan = userData.karyawan.id;
      //   this.form.id_kas = this.$route.params.id;
      // }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Kategori "${item.kategori}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("akun_kategori/save", [item])
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: "Kategori berhasil terhapus",
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        kategori: null,
        laba_rugi: null,
        neraca: null
      };
    },
    submit() {
      this.$refs.formakun_kategori.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          const payload = this.form;
          this.$store
            .dispatch("akun_kategori/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.showModal = false;
              this.getData();
              this.displaySuccess({
                message: "Kategori berhasil tersimpan",
              });
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async getData() {
      this.loading = true
      this.$store
        .dispatch("akun_kategori/getData", {
          order: "asc"
        })
        .then(() => {
          const categories = JSON.parse( JSON.stringify(this.$store.state.akun_kategori.datas) );
          categories.map((item, i) => {
            item.position = 'middle'
            if(i == 0) {
              item.position = 'first'
            }

            if(i == (categories.length - 1)) {
              item.position = 'last'
            }
          })

          this.items = categories
          this.totalRows = this.items.length;
          this.loading = false
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
